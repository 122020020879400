import { Controller } from "@hotwired/stimulus"
import SelectionArea from "@viselect/vanilla"

// Connects to data-controller="priorities--index"
export default class extends Controller {
  static outlets = ['priorities--item']
  static targets = ['selectAllCheckbox', 'batchActionsMenu']
  static values = {
    selectables: { type: String, default: 'tbody tr' }
  }

  get selectedItems() {
    return this.prioritiesItemOutlets.filter(instance => instance.selectedValue)
  }

  connect() {
    this.#selectionChanged()
    this.selectionArea = new SelectionArea({
      container: this.element,
      selectables: this.selectablesValue,
      singleTap: { allow: false }
    })

    this.selectionArea.on('beforestart', ({ event }) => !(event.target.tagName === 'INPUT'))
    this.selectionArea.on('stop', this.selectItemsInSelectionArea.bind(this))
  }

  disconnect() {
    this.selectionArea.destroy()
  }

  prioritiesItemOutletConnected(outlet, _element) {
    this.onItemCheckboxTargetChange ||= (event) => {
      if (event.currentTarget.checked) {
        this.selectionArea.select(outlet.element, true)
      } else {
        this.selectionArea.deselect(outlet.element, true)
      }

      this.#selectionChanged()
    }

    outlet.checkboxTarget.addEventListener('change', this.onItemCheckboxTargetChange)
    this.#selectionChanged()
  }

  prioritiesItemOutletDisconnected(outlet, _element) {
    outlet.checkboxTarget.removeEventListener('change', this.onItemCheckboxTargetChange)
    this.#selectionChanged()
  }

  async toggleSelectAllCheckbox() {
    await Promise.all(this.prioritiesItemOutlets.map((instance) => {
      new Promise((resolve, _reject) => {
        instance.selectedValue = this.selectAllCheckboxTarget.checked

        if (this.selectAllCheckboxTarget.checked) {
          this.selectionArea.select(instance.element, true)
        } else {
          this.selectionArea.deselect(instance.element, true)
        }

        resolve()
      })
    }))

    this.#selectionChanged()
  }

  async disable(value = true) {
    await Promise.all(this.prioritiesItemOutlets.map(instance => Promise.resolve(instance.disabledValue = value)))
    await Promise.all(this.selectedItems.map(instance => Promise.resolve(instance.loadingValue = value)))
  }

  error(message) {
    this.selectedItems.forEach(instance => instance.error(message))
  }

  reset() {
    this.#resetState()
    this.disable(false)
  }

  async selectItemsInSelectionArea({ store: { selected } }) {
    await Promise.all(selected.map(element => {
      new Promise((resolve, _reject) => {
        const instance = this.prioritiesItemOutlets.find(instance => instance.element == element)
        instance.selectedValue = !instance.selectedValue

        resolve()
      })
    }))

    this.#selectionChanged()
  }

  #resetState() {
    this.batchActionsMenuTarget.disabled = true
    this.selectAllCheckboxTarget.checked = false
    this.selectAllCheckboxTarget.indeterminate = false
  }

  #selectionChanged() {
    this.#resetState()
    if (!this.selectedItems.length) return

    this.batchActionsMenuTarget.disabled = false

    if (this.selectedItems.length == this.prioritiesItemOutlets.length) {
      this.selectAllCheckboxTarget.checked = true
    } else {
      this.selectAllCheckboxTarget.indeterminate = true
    }
  }
}
