import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="ab--configuration-loader"
export default class extends Controller {
  static targets = ['content']
  static values  = { configKey: String, endpoint: String }

  connect() {
    this.contentTarget.innerHTML = "Loading configuration file..."

    this.loadConfigurationFile()
  }

  async loadConfigurationFile() {
    await get(this.endpointValue, { query: { id: this.configKeyValue }})
      .then(async response => {this.contentTarget.innerHTML = await response.text })
      .catch(() => alert('There was a problem loading the configuration file.'))
  }
}
