import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="priorities--item"
export default class extends Controller {
  #showValidationTooltip = false

  static targets = ['input', 'checkbox']
  static classes = ['selected', 'inputInvalid', 'loading']
  static values = {
    id: Number,
    url: String,
    valid: { type: Boolean, default: undefined },
    selected: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  }

  loadingValueChanged(value, _previousValue) {
    if (value) {
      this.element.classList.add(this.loadingClass)
    } else {
      this.element.classList.remove(this.loadingClass)
    }
  }

  validValueChanged(value, _previousValue) {
    this.inputTarget.classList.remove(this.inputInvalidClass)
    bootstrap.Tooltip.getInstance(this.inputTarget)?.dispose()

    if (!value) {
      this.inputTarget.classList.add(this.inputInvalidClass)

      const tooltip = new bootstrap.Tooltip(this.inputTarget, {
        title: this.inputTarget.validationMessage,
        customClass: 'tooltip-danger',
        placement: 'top'
      })

      if (this.#showValidationTooltip) {
        tooltip.show()
        this.#showValidationTooltip = false
      }
    }
  }

  disabledValueChanged(value, _previousValue) {
    this.checkboxTarget.disabled = value

    if (this.hasInputTarget) {
      this.inputTarget.disabled = value
    }
  }

  selectedValueChanged(value, _previousValue) {
    this.element.classList.remove(this.selectedClass)
    this.checkboxTarget.checked = value

    if (value) {
      this.element.classList.add(this.selectedClass)
    }
  }

  toJSON() {
    return { id: this.idValue }
  }

  async selectionChanged(_event) {
    await Promise.resolve(this.selectedValue = this.checkboxTarget.checked)
  }

  stepDown(_event) {
    this.inputTarget.stepDown()
    this.update()
  }

  stepUp(_event) {
    this.inputTarget.stepUp()
    this.update()
  }

  async validate(_event) {
    await Promise.resolve(this.validValue = undefined)

    this.inputTarget.setCustomValidity('')
    this.inputTarget.value = this.inputTarget.value
    this.#showValidationTooltip = true

    await Promise.resolve(this.validValue = this.inputTarget.checkValidity())
  }

  async error(message, showValidationTooltip = false) {
    this.#showValidationTooltip = showValidationTooltip
    this.inputTarget.setCustomValidity(message)

    await new Promise((resolve, _reject) => {
      this.validValue = false
      this.loadingValue = false
      resolve()
    })
  }

  async update() {
    await Promise.resolve(this.validValue = this.inputTarget.checkValidity())
    if (!this.validValue) return

    const payload = new FormData()

    await new Promise((resolve, _reject) => {
      this.disabledValue = true
      this.loadingValue = true
      resolve()
    })

    payload.append(this.inputTarget.name, this.inputTarget.valueAsNumber)

    const response = await patch(this.urlValue, { responseKind: 'turbo-stream', body: payload })
    await Promise.resolve(this.disabledValue = false)

    if (!response.ok) {
      this.error('Error updating priority', true)
    }
  }
}
