import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="priorities--batch-action"
export default class extends Controller {
  static outlets = ['priorities--index']
  static values = {
    url: String,
    type: String,
    httpMethod: { type: String, default: 'patch' },
    paramKey: { type: String, default: 'priorities' },
    unitName: { type: String, default: 'item' },
    unitNamePlural: String,
    confirm: {
      type: String,
      default: 'Are you sure you want to update priorities for :count selected :unit?'
    }
  }

  payload() {
    return {
      type: this.typeValue,
      [this.paramKeyValue]: this.prioritiesIndexOutlet.selectedItems
    }
  }

  async update() {
    if (this.#confirmationPrompt() == true) {
      await this.onConfirm()
    } else {
      await this.onCancel()
    }
  }

  async onConfirm() { await this.#sendUpdateRequest() }
  async onCancel() { Promise.resolve() }

  async ok(_response) {
    Promise.resolve(this.prioritiesIndexOutlet.reset())
  }

  async error(_response) {
    await new Promise((resolve, _reject) => {
      this.prioritiesIndexOutlet.disable(false)
      this.prioritiesIndexOutlet.error('Error updating priority')
      resolve()
    })
  }

  #confirmationPrompt() {
    const message = this.confirmValue
      .replace(':count', this.prioritiesIndexOutlet.selectedItems.length)
      .replace(':unit', () => {
        if (this.prioritiesIndexOutlet.selectedItems.length > 1) {
          return this.hasUnitNamePluralValue ? this.unitNamePluralValue : `${this.unitNameValue}s`
        } else {
          return this.unitNameValue
        }
      })

    return confirm(message)
  }

  async #sendUpdateRequest() {
    this.prioritiesIndexOutlet.disable()

    const request = new FetchRequest(this.httpMethodValue, this.urlValue, {
      contentType: 'application/json',
      responseKind: 'turbo-stream',
      body: JSON.stringify(this.payload())
    })

    const response = await request.perform()

    if (response.ok) {
      await this.ok(response)
    } else {
      await this.error(response)
    }

    return response
  }
}
