import BatchActionController from "./batch_action_controller"
import { merge } from "lodash"

// Connects to data-controller="priorities--insert-selection-at"
export default class extends BatchActionController {
  static targets = ['input', 'inputErrorMessage', 'submit']
  static classes = ['inputInvalid']
  static values = merge({}, this.values, {
    type: { type: String, default: 'insert_at' },
    submitWith: { type: String, default: 'Updating...' },
    valid: { type: Boolean, default: undefined },
    disabled: { type: Boolean, default: false }
  })

  connect() {
    this.modal = bootstrap.Modal.getOrCreateInstance(this.element)
  }

  validValueChanged(value, _previousValue) {
    this.inputTarget.classList.remove(this.inputInvalidClass)
    this.submitTarget.disabled = true

    if (value) {
      this.submitTarget.disabled = false
    } else {
      this.inputErrorMessageTarget.textContent = this.inputTarget.validationMessage
      this.inputTarget.classList.add(this.inputInvalidClass)
    }
  }

  disabledValueChanged(value, _previousValue) {
    this.submitTarget.disabled = value
    this.inputTarget.disabled = value
  }

  submitTargetConnected(element) {
    this.submitTarget.setAttribute('original-submit-text', this.submitTarget.textContent.trim())
    element.disabled = true
  }

  payload() {
    return merge({}, super.payload(), { priority: this.inputTarget.valueAsNumber })
  }

  async validate(_event) {
    await Promise.resolve(this.validValue = undefined)

    this.inputTarget.setCustomValidity('')

    await Promise.resolve(this.validValue = this.inputTarget.checkValidity())
  }

  async update() {
    await Promise.resolve(this.validValue = this.inputTarget.checkValidity())
    if (!this.validValue) return

    await super.update()
  }

  async onCancel() {
    await super.onCancel()
    await Promise.resolve(this.validValue = undefined)
    await Promise.resolve(this.disabledValue = false)
  }

  async onConfirm() {
    $(this.submitTarget).text(this.submitWithValue)
    await Promise.resolve(this.disabledValue = true)
    await super.onConfirm()
  }

  async ok(_response) {
    await super.ok()
    await Promise.resolve(this.disabledValue = false)
    await Promise.resolve(this.validValue = undefined)

    this.inputTarget.value = ''
    this.submitTarget.disabled = true
    this.submitTarget.textContent = this.submitTarget.getAttribute('original-submit-text')
    this.modal.hide()
  }

  async error(_response) {
    await super.error()

    this.submitTarget.textContent = this.submitTarget.getAttribute('original-submit-text')
    await Promise.resolve(this.disabledValue = false)
    await Promise.resolve(this.validValue = undefined)

    this.inputTarget.setCustomValidity('Error updating priority')
    await Promise.resolve(this.validValue = false)
    this.submitTarget.disabled = false
  }
}
