import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  #timer

  static values = {
    remoteUrl: String,
    delay: 1000,
    complete: { type: Boolean, default: false }
  }

  connect() {
    this.#startPolling()
  }

  disconnect() {
    this.#stopPolling()
  }

  completeValueChanged(value, _previousValue) {
    if (value) {
      this.#stopPolling()
    }
  }

  #startPolling() {
    if (this.completeValue) return

    this.#timer = setInterval(this.#fetchContent.bind(this), this.delayValue)
  }

  #stopPolling() {
    clearInterval(this.#timer)
  }

  async #fetchContent() {
    await get(this.remoteUrlValue, { responseKind: 'turbo-stream' })
  }
}
