import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'
import { get } from '@rails/request.js'

// Connects to data-controller="bs--popover"
export default class extends Controller {
  static values = { options: Object, ajaxUrl: String }

  connect() {
    this.popover = new Popover(this.element, this.optionsValue)
    $(this.element).on('shown.bs.popover', this.#onPopoverOpen.bind(this))
  }

  disconnect() {
    this.popover.dispose()
    this.popover = null
  }

  hide() {
    Popover.getInstance(this.element)?.hide()
  }

  async #onPopoverOpen() {
    this.application.controllers
      .filter((instance) => instance.identifier == 'bs--popover' && instance.element != this.element)
      .forEach((instance) => instance.hide())

    if (!this.hasAjaxUrlValue) return

    const params = new URLSearchParams()
    params.append('target', this.popover.tip.id)

    await get(this.ajaxUrlValue, {
      responseKind: 'turbo-stream',
      query: Object.fromEntries(params)
    })

    $(`#${this.popover.tip.id}`).one('click', '[data-popover-dismiss]', this.hide.bind(this))
  }
}
